.content-header {

    &.image {
        background-position: center center;
        background-size: cover;
        background-attachment: fixed;
        overflow: hidden;
        color: $primary-text-color;

        h1 { 
            color: $primary-text-color;
            text-shadow: $text-shadow; 
        }

        .legend { max-width: 600px; }

        .text-container {
            float: left;
            position: relative;
            
            div { position: relative; }

            &:before {
                $padding: 100px;

                background: rgba($primary,0.8);
                background: -moz-linear-gradient(top,rgba($primary, 0.8) 0%, rgba(darken($primary, 15%), 0.8) 100%);
                background: -webkit-linear-gradient(top, rgba($primary, 0.8) 0%, rgba(darken($primary, 15%), 0.8) 100%);
                background: linear-gradient(to bottom,rgba($primary, 0.8) 0%, rgba(darken($primary, 15%), 0.8) 100%);

                @include transform(translateX(-#{$padding / 1.3}) skew(-45deg, 0deg));
                content: ' ';
                position: absolute;

                height: 100%;
                width: calc(100% + #{$padding});

                z-index: 0;

                @include media-breakpoint-down('sm', $grid-breakpoints) {
                    display: none;
                }
            }
        }


        @include media-breakpoint-down('sm', $grid-breakpoints) {
            position: relative;
            &:before {
                background: rgba($primary,0.8);
                background: -moz-linear-gradient(top,rgba($primary, 0.8) 0%, rgba(darken($primary, 15%), 0.8) 100%);
                background: -webkit-linear-gradient(top, rgba($primary, 0.8) 0%, rgba(darken($primary, 15%), 0.8) 100%);
                background: linear-gradient(to bottom,rgba($primary, 0.8) 0%, rgba(darken($primary, 15%), 0.8) 100%);
                
                height: 100%;
                width: 100%;
                z-index: 0;
                position: absolute;
                content: ' ';
            }
        }
    }
}