#accordion {
  margin-top: 20px;

  a {
    text-decoration: none;
    color: $head-text;
  }

  a:before {
    display: inline-block;
    width: 30px;
    text-align: center;
  }

  // tous les liens
  a:before, a[class=""]:before {
    font-family: FontAwesome;
    content: "\f077" // fa-chevron-up
  }

  a.collapsed:before {
    font-family: FontAwesome;
    content: "\f054"; // fa-chevron-right
  }

  .card-header {
    margin-bottom: 15px;
  }

  .card-block {
    margin-bottom: 40px;
  }
}
//[aria-expanded=false]