@import "mixin/button";

.form-control-lg {
    font-size: 1rem;
}

.btn:focus, .btn.focus, .btn:active:focus, 
.btn:active.focus, .btn.active:focus, .btn.active.focus {
    outline: none;
}

// Test shadow buttons
.btn:hover {
    box-shadow: 4px 4px 10px;
    outline: invert;
}

.btn-white {
    @include button-variant($gray-medium, #fff, $input-border);
}

.btn-primary {
    text-shadow: $text-shadow;
    box-shadow: 2px 2px 10px $primary-darken;
}
.btn-primary:hover {
    background-color: $primary-darken;
    border-color: $primary-darken;
    box-shadow: 4px 4px 10px darken($primary-darken, 10%);
}

.btn-secondary {
    text-shadow: $text-shadow;
    box-shadow: 2px 2px 10px $secondary-darken;
}
.btn-secondary:hover {
    background-color: $secondary-darken;
    border-color: $secondary-darken;
    box-shadow: 4px 4px 10px darken($secondary-darken, 10%);
}

.btn-thirdary {
    text-shadow: $text-shadow;
    box-shadow: 2px 2px 10px $thirdary-darken;
}
.btn-thirdary:hover {
    background-color: $thirdary-darken;
    border-color: $thirdary-darken;
    box-shadow: 4px 4px 10px darken($thirdary-darken, 10%);
}

.btn-light-grey {
    text-shadow: $text-shadow;
    box-shadow: 2px 2px 10px $light-grey-darken;
}
.btn-light-grey:hover {
    background-color: $light-grey-darken;
    border-color: $light-grey-darken;
    box-shadow: 2px 2px 10px darken($light-grey-darken, 10%);
}

.btn-dark-grey {
     text-shadow: $text-shadow;
     box-shadow: 2px 2px 10px $dark-grey-darken
 }
.btn-dark-grey:hover {
    background-color: $dark-grey-darken;
    border-color: $dark-grey-darken;
    box-shadow: 2px 2px 10px darken($dark-grey-darken, 10%);
}

.btn.btn-round {
    border-radius: 50vh;
}

.btn.btn-rect {
    border-radius: 0px;
}

.btn.btn-bold {
    font-weight: bold;
}

/** breadcrumb **/
.breadcrumb {
    border-bottom: 1px solid $gray-ultra-light;
    font-family: $primary-typo;
    padding-left: 0px;
    padding-right: 0px;
    text-transform: capitalize;
}

.breadcrumb-item + .breadcrumb-item::before {
    content: "\f105";
    font: normal normal normal 14px/1 FontAwesome;
    color: $text-color;
}

.breadcrumb-item a {
    color: $text-color;
    border-bottom: none;
}

.breadcrumb-item.active a, .breadcrumb-item a:hover, .breadcrumb-item a:focus {
    color: $primary;
}

.form-control::-moz-placeholder {
    color: $input-color-placeholder;
}
.form-control:-moz-placeholder {
    color: $input-color-placeholder;
}
.form-control:-ms-input-placeholder {
    color: $input-color-placeholder;
}
.form-control::-webkit-input-placeholder {
    color: $input-color-placeholder;
}

.btn-compound-primary {
  @include button-compound-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border);
}
.btn-compound-secondary {
  @include button-compound-variant($btn-secondary-color, $btn-secondary-bg, $btn-secondary-border);
}
.btn-compound-info {
  @include button-compound-variant($btn-info-color, $btn-info-bg, $btn-info-border);
}
.btn-compound-success {
  @include button-compound-variant($btn-success-color, $btn-success-bg, $btn-success-border);
}
.btn-compound-warning {
  @include button-compound-variant($btn-warning-color, $btn-warning-bg, $btn-warning-border);
}
.btn-compound-danger {
  @include button-compound-variant($btn-danger-color, $btn-danger-bg, $btn-danger-border);
}
.btn-compound-white {
  @include button-compound-variant($text-color, #fff, $input-border);
}

.btn-white-border {
    border: 2px solid #fff;
}

.btn-circle, .btn-circle-full {
    text-align: center;
    padding-left: 0px;
    padding-right: 0px;

    &:focus, &.focus, &:active:focus, &:active.focus, 
    &.active:focus, &.active.focus {
        outline: 0px;
    }
}

.btn.btn-circle, .btn.btn-circle-full {
    width: $btn-padding-y * 2 + $btn-line-height;
    height: $btn-padding-y * 2 + $btn-line-height;
    border-radius: ($btn-padding-y * 2 + $btn-line-height) / 2;
} 

.btn.btn-sm.btn-circle, .btn.btn-sm.btn-circle-full {
    width: $btn-padding-y-sm * 2 + $btn-line-height;
    height: $btn-padding-y-sm * 2 + $btn-line-height;
    border-radius: ($btn-padding-y-sm * 2 + $btn-line-height) / 2;
}

.btn.btn-lg.btn-circle, .btn.btn-lg.btn-circle-full {
    width: $btn-padding-y-lg * 2 + $btn-line-height;
    height: $btn-padding-y-lg * 2 + $btn-line-height;
    border-radius: ($btn-padding-y-lg * 2 + $btn-line-height) / 2;
}

.btn-compound {
    cursor: default;
    @include user-select(none);
    padding: 0px;
    line-height: 1;
    
    > .btn {
        border-radius: 0px;
    }

    & > * {
        padding-right: $grid-gutter-width / 3;   
        padding-left: $grid-gutter-width / 3;   
    }

    .btn.btn-circle:first-child {
        border-bottom-left-radius: 50vh;
        border-top-left-radius: 50vh; 
        border-bottom-right-radius: 0px;
        border-top-right-radius: 0px; 
    }

    .btn.btn-circle:last-child {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 50vh;
        border-top-right-radius: 50vh; 
    }
}

.fa {
    color: $thirdary;
}