.landing {
    margin-bottom: 80px;

    p {
        font-size: 0.9em;
    }
}

.equal-height {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
}

#landing-discover {
    display: flex;
    font-family: 'Nunito', sans-serif;
    line-height: 1.3;

    .container {
        margin: auto;
    }

    @include media-breakpoint-up('md', $grid-breakpoints) {
        height: 500px;
        background-image: url('/images/doctor.jpg');
        background-repeat: no-repeat;
        background-position: bottom right;
        background-size: cover;
    }

    @include media-breakpoint-down('sm', $grid-breakpoints) {
        text-align: center;
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#dfecf2+0,dbe8f0+100 */
        background: $gradient-dark; /* Old browsers */
        background: -moz-linear-gradient(top,  $gradient-dark 0%, $gradient-light 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top,  $gradient-dark 0%, $gradient-light 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom,  $gradient-dark 0%, $gradient-light 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#D4BC9E', endColorstr='#E0CDB5',GradientType=0 ); /* IE6-9 */
    }

    .btn {
        font-family: 'Open Sans', sans-serif;
        font-weight: 800;
        text-shadow: $text-shadow;
        font-size: 0.9rem;
        line-height: 1rem;
    }

    #discover-text {
        color: white;
        font-size: 2.5em;
        font-weight: 900;
        text-shadow: $text-shadow;

        h1 {
            text-shadow: none;
            color: white;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 0.4em;
            margin-bottom: 0;
            letter-spacing: 0.15em;
        }
    }
}

#landing-steps {
    text-align: center;

    .container{
        max-width: 90%;
    }

    .btn {
        font-weight: 800;
        font-size: 0.9em;
    }

    #steps-main {
        width: 80%;
        margin: 0 auto 30px auto;
        padding: 0 0 30px 0;
        border-bottom: 1px solid $border-color;
    }

    section {
        display: flex;
        flex-wrap: wrap;
        text-align: left;
        margin: 40px auto;
        padding: 0 5px;

        .image {
            margin: 0;
            padding: 0;

            @include media-breakpoint-down('lg', $grid-breakpoints) {
                margin: 0;
                padding: auto;
            }
        }

        .description {
            margin: auto;
            padding: 4px 8px;
            font-size: 0.9em;

            h4 {
                line-height: 20px;
                margin-bottom: 10px;
            }
        }


    }

    @include media-breakpoint-down('md', $grid-breakpoints) {
        .container {
            max-width: 80%;
        }
        section {
            margin: 20px auto;
        }
    }

    @include media-breakpoint-down('sm', $grid-breakpoints) {
        .container {
            max-width: 95%;
        }

        section {
            margin: 10px auto;
        }
    }
}

#landing-hopital {
    background: url('/images/hopital.jpg') center center no-repeat;
    background-size: cover;
    text-align: center;

    .btn {
        font-weight: bold;
        font-size: 0.9em;
        box-shadow: none;
    }

    #opacity-frame {
        padding: 180px 0;
        background: rgba($background-light, 0.3);
    }

    #white-frame {
        position: relative;
        width: 60%;
        margin: auto;
        background: $background-light;
        padding: 80px;

        .btn-box {
            position: absolute;
            left: 0;
            right: 0;
            bottom: -20px;
        }
    }

    @include media-breakpoint-down('md', $grid-breakpoints) {
        #opacity-frame {
            padding: 80px 0;
        }

        #white-frame {
            width: 60%;
            padding: 60px;
        }

        .btn {
            padding: 15px;
        }
    }

    @include media-breakpoint-down('sm', $grid-breakpoints) {
        #white-frame {
            width: 70%;
            padding: 40px;
        }
    }

    @include media-breakpoint-down('xs', $grid-breakpoints) {
        h2 {
            font-size: 1.6em;
        }
        #white-frame {
            width: 70%;
            padding: 25px;
        }

        .btn {
            padding: 10px;
        }
    }
}

#landing-medical {
    text-align: center;

    .btn-group {
        position: relative;
        bottom: -20px;
        padding: 0;
    }

    .btn {
        font-weight: bold;
        font-size: 0.9em;
    }

    .xs-buttons {
        padding: 11px;
        font-weight: bold;
    }

    #medical-main {
        width: 70%;
        margin: 50px auto 80px auto;
        border-bottom: 1px solid $border-color;

        #medical-title {
            margin-bottom: 40px;
        }
    }

    .gain-medical {
        text-align: left;

        .atout {
            margin-bottom: 25px;
            padding: 0;
        }
    }

    .icone-container {
        text-align: center;
        margin: auto;
    }
}

#landing-trust {
    padding: 40px 10px;
    background: $trust-background;
    text-align: center;
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
}

#landing-trust-logos {
    text-align: center;
    margin: 60px auto;

    .logo {
        padding: 15px;
        text-align: center;
        margin: 20px auto;

        .contain {
            max-height: 200px;
            min-height: 100px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
        }
    }
}


/* // Plus utilisé
#landing-quotes {
    color: #fff;
    font-family: $primary-typo;
    font-weight: bold;
    background: transparent url('/images/backquote.png') center center no-repeat;
    height: 365px;
    background-size: cover;
    padding: 115px 0px;
    text-align: center;
    font-size: 1.6rem;
    .btn { 
        font-weight: bold; 
        font-size: 0.9rem; 
        color: #fff;
    }
}

// Plus utilisé
#landing-posts {
    .cat {
        margin-top: 15px;
        font-weight: bold;
        font-size: 0.9rem;
    }

    .navigation .btn {
        margin-bottom: 5px;
    }


    @include media-breakpoint-down('sm', $grid-breakpoints) {
        .cat {
            margin-bottom: 35px;
        }
    }
} */

#border-div {
    width: 50%;
    margin: 30px auto;
    border-bottom: 1px solid $border-color;
}

/* {
    border: 1px solid red;
}*/