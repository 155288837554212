// utilisée pour header background
$background-light: #FFF;

// utilisée pour btn-primary
$primary: #87775E;
$primary-lighten: lighten($primary, 3%);
$primary-darken: darken($primary, 3%);
$primary-text-color: #fff;

// utilisée pour btn-secondary
$secondary: darken($primary, 15%);
$secondary-lighten: lighten($secondary, 3%);
$secondary-darken: darken($secondary, 3%);
$secondary-text-color: #fff;

// utilisée pour btn-thirdary
$thirdary: #BB9C6B;
$thirdary-lighten: lighten($thirdary, 3%);
$thirdary-darken: darken($thirdary, 3%);
$thirdary-text-color: #fff;

// utilisée pour btn-dark-grey
$dark-grey: #898989;
$dark-grey-lighten: lighten($dark-grey, 3%);
$dark-grey-darken: darken($dark-grey, 3%);
$dark-grey-text-color: #fff;

// utilisée pour btn-light-grey
$light-grey: #9D9D9D;
$light-grey-lighten: lighten($light-grey, 3%);
$light-grey-darken: darken($light-grey, 3%);
$light-grey-text-color: #fff;

// utilisée pour border
$border-color: #D9D9D9;

// utilisée pour le gradient de la home
$gradient-light: #E0CDB5;
$gradient-dark: #D4BC9E;

// utilisée pour #landing-trust
$trust-background: #F2F2F2;

// utilisée pour footer -> copyright & btn
$copyright-color: #555A5F;

$gray-medium: #6b727a; 
$gray-light: #e6e6e6;
$gray-ultra-light: #f0f0f0;

$main-typo: 'Open Sans', sans-serif;
$primary-typo: 'Nunito', sans-serif;

$text-color: #777777;
$head-text: #464646; 

$footer-gray: #818181;

$input-bg: #fff;
$input-color: $head-text;
$input-border: #e4e4e4;

$text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);

/** Font awesome **/
$fa-font-path: '/fonts';

/** main z-index **/
$zindex-base: 10;
$zindex-overlay: 999;