#team-background {
    img {
        width: 100%;
        height: auto;
    }
}

.contact-cont {
    width: 100%;
    padding: 30px 40px 40px 40px;

    h3 {
        margin: 20px 0px 50px 0px;
    }

    .contact-form {
        padding: 30px;

        .form-control {
            margin-bottom: 20px;
        }

        textarea {
            min-height: 230px;
            margin-bottom: 0px !important;
        }
    }

    .btn-primary {
        margin-top: 40px;
    }
}

@include media-breakpoint-down('xs', $grid-breakpoints) {
    .contact-cont {
        padding: 30px 10px 40px 10px;
    }
}