/* transition property & duration */
.ng-trans {
  -webkit-transition: 240ms all;
  transition: 240ms all;
}
.ng-trans.ng-hide-add,
.ng-trans.ng-hide-remove {
  display: inherit !important;
}
/* transition types */
.ng-trans-fade.ng-enter, .ng-trans-fade.ng-leave.ng-leave-active, .ng-trans-fade.ng-hide-remove, .ng-trans-fade.ng-hide-add.ng-hide-add-active {
  opacity: 0;
}
.ng-trans-fade.ng-leave, .ng-trans-fade.ng-enter.ng-enter-active, .ng-trans-fade.ng-hide-remove.ng-hide-remove-active, .ng-trans-fade.ng-hide-add {
  opacity: 1;
}
.ng-trans-fade-left.ng-enter, .ng-trans-fade-left.ng-leave.ng-leave-active, .ng-trans-fade-left.ng-hide-remove, .ng-trans-fade-left.ng-hide-add.ng-hide-add-active {
  opacity: 0;
  -webkit-transform: translate(-40px, 0);
  -ms-transform: translate(-40px, 0);
  transform: translate(-40px, 0);
}
.ng-trans-fade-left.ng-leave, .ng-trans-fade-left.ng-enter.ng-enter-active, .ng-trans-fade-left.ng-hide-remove.ng-hide-remove-active, .ng-trans-fade-left.ng-hide-add {
  opacity: 1;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}
.ng-trans-fade-up.ng-enter, .ng-trans-fade-up.ng-leave.ng-leave-active, .ng-trans-fade-up.ng-hide-remove, .ng-trans-fade-up.ng-hide-add.ng-hide-add-active {
  opacity: 0;
  -webkit-transform: translate(0, -40px);
  -ms-transform: translate(0, -40px);
  transform: translate(0, -40px);
}
.ng-trans-fade-up.ng-leave, .ng-trans-fade-up.ng-enter.ng-enter-active, .ng-trans-fade-up.ng-hide-remove.ng-hide-remove-active, .ng-trans-fade-up.ng-hide-add {
  opacity: 1;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}
.ng-trans-fade-right.ng-enter, .ng-trans-fade-right.ng-leave.ng-leave-active, .ng-trans-fade-right.ng-hide-remove, .ng-trans-fade-right.ng-hide-add.ng-hide-add-active {
  opacity: 0;
  -webkit-transform: translate(40px, 0);
  -ms-transform: translate(40px, 0);
  transform: translate(40px, 0);
}
.ng-trans-fade-right.ng-leave, .ng-trans-fade-right.ng-enter.ng-enter-active, .ng-trans-fade-right.ng-hide-remove.ng-hide-remove-active, .ng-trans-fade-right.ng-hide-add {
  opacity: 1;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}
.ng-trans-fade-down.ng-enter, .ng-trans-fade-down.ng-leave.ng-leave-active, .ng-trans-fade-down.ng-hide-remove, .ng-trans-fade-down.ng-hide-add.ng-hide-add-active {
  opacity: 0;
  -webkit-transform: translate(0, 40px);
  -ms-transform: translate(0, 40px);
  transform: translate(0, 40px);
}
.ng-trans-fade-down.ng-leave, .ng-trans-fade-down.ng-enter.ng-enter-active, .ng-trans-fade-down.ng-hide-remove.ng-hide-remove-active, .ng-trans-fade-down.ng-hide-add {
  opacity: 1;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}
.ng-trans-slide-left.ng-enter, .ng-trans-slide-left.ng-leave.ng-leave-active, .ng-trans-slide-left.ng-hide-remove, .ng-trans-slide-left.ng-hide-add.ng-hide-add-active {
  opacity: 0;
  -webkit-transform: translate(-100%, 0);
  -ms-transform: translate(-100%, 0);
  transform: translate(-100%, 0);
}
.ng-trans-slide-left.ng-leave, .ng-trans-slide-left.ng-enter.ng-enter-active, .ng-trans-slide-left.ng-hide-remove.ng-hide-remove-active, .ng-trans-slide-left.ng-hide-add {
  opacity: 1;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}
.ng-trans-slide-up.ng-enter, .ng-trans-slide-up.ng-leave.ng-leave-active, .ng-trans-slide-up.ng-hide-remove, .ng-trans-slide-up.ng-hide-add.ng-hide-add-active {
  opacity: 0;
  -webkit-transform: translate(0, -800px);
  -ms-transform: translate(0, -800px);
  transform: translate(0, -800px);
}
.ng-trans-slide-up.ng-leave, .ng-trans-slide-up.ng-enter.ng-enter-active, .ng-trans-slide-up.ng-hide-remove.ng-hide-remove-active, .ng-trans-slide-up.ng-hide-add {
  opacity: 1;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}
.ng-trans-slide-right.ng-enter, .ng-trans-slide-right.ng-leave.ng-leave-active, .ng-trans-slide-right.ng-hide-remove, .ng-trans-slide-right.ng-hide-add.ng-hide-add-active {
  opacity: 0;
  -webkit-transform: translate(100%, 0);
  -ms-transform: translate(100%, 0);
  transform: translate(100%, 0);
}
.ng-trans-slide-right.ng-leave, .ng-trans-slide-right.ng-enter.ng-enter-active, .ng-trans-slide-right.ng-hide-remove.ng-hide-remove-active, .ng-trans-slide-right.ng-hide-add {
  opacity: 1;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}
.ng-trans-slide-down.ng-enter, .ng-trans-slide-down.ng-leave.ng-leave-active, .ng-trans-slide-down.ng-hide-remove, .ng-trans-slide-down.ng-hide-add.ng-hide-add-active {
  opacity: 0;
  -webkit-transform: translate(0, 800px);
  -ms-transform: translate(0, 800px);
  transform: translate(0, 800px);
}
.ng-trans-slide-down.ng-leave, .ng-trans-slide-down.ng-enter.ng-enter-active, .ng-trans-slide-down.ng-hide-remove.ng-hide-remove-active, .ng-trans-slide-down.ng-hide-add {
  opacity: 1;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}
.ng-trans-scale-up.ng-enter, .ng-trans-scale-up.ng-leave.ng-leave-active, .ng-trans-scale-up.ng-hide-remove, .ng-trans-scale-up.ng-hide-add.ng-hide-add-active {
  opacity: 0;
  -webkit-transform: scale(0.64);
  -ms-transform: scale(0.64);
  transform: scale(0.64);
}
.ng-trans-scale-up.ng-leave, .ng-trans-scale-up.ng-enter.ng-enter-active, .ng-trans-scale-up.ng-hide-remove.ng-hide-remove-active, .ng-trans-scale-up.ng-hide-add {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.ng-trans-scale-down.ng-enter, .ng-trans-scale-down.ng-leave.ng-leave-active, .ng-trans-scale-down.ng-hide-remove, .ng-trans-scale-down.ng-hide-add.ng-hide-add-active {
  opacity: 0;
  -webkit-transform: scale(1.68);
  -ms-transform: scale(1.68);
  transform: scale(1.68);
}
.ng-trans-scale-down.ng-leave, .ng-trans-scale-down.ng-enter.ng-enter-active, .ng-trans-scale-down.ng-hide-remove.ng-hide-remove-active, .ng-trans-scale-down.ng-hide-add {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.ng-trans-flip-x.ng-enter, .ng-trans-flip-x.ng-leave.ng-leave-active, .ng-trans-flip-x.ng-hide-remove, .ng-trans-flip-x.ng-hide-add.ng-hide-add-active {
  opacity: 0;
  -webkit-transform: perspective(400px) rotateX(90deg);
  -ms-transform: perspective(400px) rotateX(90deg);
  transform: perspective(400px) rotateX(90deg);
}
.ng-trans-flip-x.ng-leave, .ng-trans-flip-x.ng-enter.ng-enter-active, .ng-trans-flip-x.ng-hide-remove.ng-hide-remove-active, .ng-trans-flip-x.ng-hide-add {
  opacity: 1;
  -webkit-transform: perspective(400px) rotateX(0deg);
  -ms-transform: perspective(400px) rotateX(0deg);
  transform: perspective(400px) rotateX(0deg);
}
.ng-trans-flip-y.ng-enter, .ng-trans-flip-y.ng-leave.ng-leave-active, .ng-trans-flip-y.ng-hide-remove, .ng-trans-flip-y.ng-hide-add.ng-hide-add-active {
  opacity: 0;
  -webkit-transform: perspective(200px) rotateY(90deg);
  -ms-transform: perspective(200px) rotateY(90deg);
  transform: perspective(200px) rotateY(90deg);
}
.ng-trans-flip-y.ng-leave, .ng-trans-flip-y.ng-enter.ng-enter-active, .ng-trans-flip-y.ng-hide-remove.ng-hide-remove-active, .ng-trans-flip-y.ng-hide-add {
  opacity: 1;
  -webkit-transform: perspective(200px) rotateY(0deg);
  -ms-transform: perspective(200px) rotateY(0deg);
  transform: perspective(200px) rotateY(0deg);
}
.ng-trans-rotate.ng-enter, .ng-trans-rotate.ng-leave.ng-leave-active, .ng-trans-rotate.ng-hide-remove, .ng-trans-rotate.ng-hide-add.ng-hide-add-active {
  opacity: 0;
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
.ng-trans-rotate.ng-leave, .ng-trans-rotate.ng-enter.ng-enter-active, .ng-trans-rotate.ng-hide-remove.ng-hide-remove-active, .ng-trans-rotate.ng-hide-add {
  opacity: 1;
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
}
/* transition timing functions */
.easeInCubic.ng-trans {
  -webkit-transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.easeOutCubic.ng-trans {
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.easeInOutCubic.ng-trans {
  -webkit-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}
.easeInQuad.ng-trans {
  -webkit-transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
.easeOutQuad.ng-trans {
  -webkit-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.easeInOutQuad.ng-trans {
  -webkit-transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.easeInQuart.ng-trans {
  -webkit-transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
}
.easeOutQuart.ng-trans {
  -webkit-transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}
.easeInOutQuart.ng-trans {
  -webkit-transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
}
.easeInQuint.ng-trans {
  -webkit-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.easeOutQuint.ng-trans {
  -webkit-transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.easeInOutQuint.ng-trans {
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
}
.easeInSine.ng-trans {
  -webkit-transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
}
.easeOutSine.ng-trans {
  -webkit-transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
}
.easeInOutSine.ng-trans {
  -webkit-transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
}
.easeInExpo.ng-trans {
  -webkit-transition-timing-function: cubic-bezier(0.95, 0.05, 0.795, 0.035);
  transition-timing-function: cubic-bezier(0.95, 0.05, 0.795, 0.035);
}
.easeOutExpo.ng-trans {
  -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}
.easeInOutExpo.ng-trans {
  -webkit-transition-timing-function: cubic-bezier(1, 0, 0, 1);
  transition-timing-function: cubic-bezier(1, 0, 0, 1);
}
.easeInCirc.ng-trans {
  -webkit-transition-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.335);
  transition-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.335);
}
.easeOutCirc.ng-trans {
  -webkit-transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}
.easeInOutCirc.ng-trans {
  -webkit-transition-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
.easeInBack.ng-trans {
  -webkit-transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
.easeOutBack.ng-trans {
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.easeInOutBack.ng-trans {
  -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
