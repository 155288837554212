#footer {
  h5 {
    font-weight: 700;
    color: #fff;
  }

  .footer-top {
    padding: 70px 20px;
    background: transparent url('/images/bg-footer-top.png') repeat;
    color: $primary;
    border-top: 1px solid $border-color;

    .footer-top-text, .footer-top-btn {
      padding:0;
      margin-bottom: 10px;
    }

    .footer-top-btn {
      display: flex;
    }

    p {
      margin: auto 0;
    }

    .btn {
      color: $color-white;
      font-weight: 800;
      font-size: 0.9rem;
      margin: auto;
    }
  }

  .footer-bottom {
    background: transparent url('/images/bg-footer-bottom.png') repeat;
    color: #fff;
    padding-bottom: 50px;
  }

  .separator {
    margin: 70px 0 55px 0;
    background-color: rgba(255, 255, 255, 0.25);
    height: 1px;
  }

  ul a, ul a:hover, ul a:visited, ul a:focus {
    text-transform: capitalize;
    color: #DDD;
  }

  .btn-footer {
    text-shadow: $text-shadow;
    box-shadow: 2px 2px 10px $copyright-color;
    font-weight: 700;
    padding: 12px 20px;
    margin-bottom: 20px;
  }
  .btn-footer:hover {
    box-shadow: 4px 4px 10px $copyright-color;
  }

  .fa {
    color: $color-white;
  }

  .services, .menu {
    ul, li {
      display: block;
      padding: 0;
    }

    li {
      a:hover::before {
        color: #fff;
      }

      a::before {
        color: $footer-gray;
        content: "\f18e";
        font: normal normal normal 14px/1 FontAwesome;
        display: inline-block;
        margin-right: 15px;
      }
    }
  }

  a.active {
    color: white;
  }

  .custom-select {
    &:hover, &.open {
      & > a.dropdown-toggle {
        color: $head-text;

        b:before {
          color: $text-color;
        }
      }
    }
    .dropdown-menu > ul a {
      color: $text-color;
    }
  }
}