.copyright {
    background-color: $copyright-color;
    color: #909ba2;
    line-height: 90px;
    font-size: 0.9em;

    a, a:focus, a:hover, a:visited {
        color: #fff;
        font-weight: bold;
    }
}