@-webkit-keyframes ngdialog-flyin {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes ngdialog-flyin {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes ngdialog-flyout {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
  }
}

@keyframes ngdialog-flyout {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
  }
}

$dialog-contact-header-height: 500px;

body.ngdialog-open {
  /*padding: 0px !important;*/
}

.ng-dialog {
/*
    .ngdialog-close:before {
        background: transparent;
        border-radius: 3px;
        color: #bbb;
        content: '\00D7';
        font-size: 26px;
        font-weight: 400;
        height: 30px;
        line-height: 26px;
        position: absolute;
        right: 3px;
        text-align: center;
        top: 3px;
        width: 30px;
    }

    .ngdialog-close:hover:before,
    .ngdialog-close:active:before {
        color: #777;
    }
*/
    .ngdialog-message {
        margin-bottom: .5em;
    }

    .ngdialog-input {
        margin-bottom: 1em;
    }

    .ngdialog-input textarea,
    .ngdialog-input input[type="text"],
    .ngdialog-input input[type="password"],
    .ngdialog-input input[type="email"],
    .ngdialog-input input[type="url"] {
        background: #fff;
        border: 0;
        border-radius: 3px;
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
        margin: 0 0 .25em;
        min-height: 2.5em;
        padding: .25em .67em;
        width: 100%;
    }

    .ngdialog-input textarea:focus,
    .ngdialog-input input[type="text"]:focus,
    .ngdialog-input input[type="password"]:focus,
    .ngdialog-input input[type="email"]:focus,
    .ngdialog-input input[type="url"]:focus {
        box-shadow: inset 0 0 0 2px #8dbdf1;
        outline: none;
    }

    .ngdialog-buttons {
        *zoom: 1;
    }

    .ngdialog-buttons:after {
        content: '';
        display: table;
        clear: both;
    }

    .ngdialog-button {
        border: 0;
        border-radius: 3px;
        cursor: pointer;
        float: right;
        font-family: inherit;
        font-size: .8em;
        letter-spacing: .1em;
        line-height: 1em;
        margin: 0 0 0 .5em;
        padding: .75em 2em;
        text-transform: uppercase;
    }

    .ngdialog-button:focus {
        -webkit-animation: ngdialog-pulse 1.1s infinite;
        animation: ngdialog-pulse 1.1s infinite;
        outline: none;
    }

    @media (max-width: 568px) {
        .ngdialog-button:focus {
            -webkit-animation: none;
            animation: none;
        }
    }

    .ngdialog-button.ngdialog-button-primary {
        background: #3288e6;
        color: #fff;
    }

    .ngdialog-button.ngdialog-button-secondary {
        background: #e0e0e0;
        color: #777;
    }
}