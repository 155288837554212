.blog {
    .pagination {
        margin: 30px 0px 0px 0px;
    }
}

.categories {
    border-bottom-right-radius: 40px;
    padding: 20px 30px;

    h4 { font-weight: bold }
    
    nav {
        ul, li {
            display: block;
            padding: 0px;
        }

        li {
            padding: 6px 0px;

            a::before {
                color: #ccaed2;
                content: "\f18e";
                font: normal normal normal 14px/1 FontAwesome;
                display: inline-block;
                margin-right: 15px;
            }


            a:hover, a.active {
                font-weight: bold;

                &::before {
                    color: #fff;
                }
            }
        }
    }

    @include media-breakpoint-down('md', $grid-breakpoints) { 
        margin-top: 30px;
    }
}

.post {
    border: 1px solid $gray-ultra-light;

    .image {
        position: relative;

        img {
            width: 100%;
            height: auto;
        }

        .date {
            background-color: $primary;
            border-radius: 3px;
            font-weight: bold;
            color: #fff;
            text-shadow: $text-shadow; 
            line-height: 40px;
            padding: 0px 15px;
            text-transform: uppercase;
            position: absolute;
            bottom: 30px;
            left: 30px;
        }
    }

    .content {
        padding: 50px;
    }

    .informations {
        font-family: $primary-typo;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 0.9em;
        padding-bottom: 20px;
        border-bottom: 1px solid $gray-ultra-light;
        margin-bottom: 30px;

        .separator {
            padding: 0px 15px;
            font-weight: normal;
        }
    }

    h2 a {
        color: $head-text;
    }

    &.post-brief{
        cursor: pointer;

        &:hover {
            background-color: #f5f8fb;
        }
    }


    &.borderless {
        border: none;

        .content {
            padding: 50px 0px;
        }
    }

    footer.informations {
        border-top: 1px solid $gray-ultra-light;
        border-width: 1px 0px 0px 0px;
        padding: 15px 0px;
    }
}